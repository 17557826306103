@use "../../../assets/sass/variables";
@import "../../../assets/sass/layout/search.scss";

.patients-tab {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 10px;
  row-gap: 40px;
  .ant-table-wrapper {
    grid-column: span 2;
    background: #fff;
    border-radius: 3px;
  }

  .tb-scroll {
    .ant-table {
      .ant-table-tbody {
        max-height: calc(100vh - 312px);
      }
    }
  }

  .ant-table .ant-table-container {
    text-align: left;
  }
}
// .patients-list{
//   .ant-table .ant-table-container table .ant-table-cell:last-child {
//     text-align: left;
//     &>div{
//     border: 1px solid variables.$CardBorderColor;
//     box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
//     border-radius: 3px;
//     display: inline-block;
//     padding: 6px 10px;
//     font-weight: 600;
//     }
//   }
// }
