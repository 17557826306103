@use "../../../assets/sass/variables";

.disable-link {
  pointer-events: none;
}

.ant-layout-sider-trigger {
  // font-size: 14px;
  margin-bottom: 10px !important;
  width: auto !important;
  background: transparent !important;
  svg {
    width: 14px;
    height: 14px;
  }
}

.sidebar {
  .logo {
    // width: 39px;
    // height: 36px;
    margin-bottom: 40px;
  }
  &.ant-layout-sider {
    // border-left: 4px solid variables.$primaryColor;
    // border-right: 1px solid rgba(255, 255, 255, 0.12);
    // width: 100%;
    // min-width: 210px;
    padding: 25px;
    background: variables.$bgBaseColor;
    height: 100%;
  }

  &-nav {
    // li {
    //   text-align: center;
    // }

    &-link {
      // font-size: 14px;
      margin: 30px 0;
      position: relative;
      color: #bdbdbe;

      &.active,
      &:hover {
        // stroke:  variables.$primaryColor;
        color: variables.$baseColor;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.rotate-icon {
  transform: rotate(180deg);
}

// .back {
//   background: rgba(232, 174, 85, 0.12);
//   padding: 16px 20px;
//   border-radius: 0 30px 30px 0;
//   margin-right: 35px;
//   gap: 10px;
//   cursor: pointer;
//   font-size: 14px;

//   svg {
//     align-self: center;
//     display: flex;
//   }
// }
