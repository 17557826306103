@use "../../assets/sass/variables";

.customize-range-silder {
  &.ant-slider {
    width: calc(100% - 40px);
    padding-top: 16px;
    height: 50px;
    margin: 0 10px 0 15px;
  }
  .ant-slider-rail {
    height: 8px;
    border-radius: 25px;
    background-color: variables.$borderColor;
  }
  .ant-slider-handle {
    background: variables.$linkColor;
    border: 8px solid variables.$linkColor;
    outline: 6px solid rgba(255, 255, 255, 0.5);
    &:focus {
      border-color: variables.$linkColor;
      outline: none;
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
    }
  }
  .ant-slider-track {
    height: 8px;
    background-color: #475569;
    border-radius: 25px;
  }
  &.ant-slider:hover {
    .ant-slider-rail {
      background-color: variables.$borderColor;
    }
    .ant-slider-track {
      background-color: variables.$linkColor;
    }
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: variables.$linkColor;
    }
  }
  .ant-slider-step {
    display: none;
  }
  .ant-slider-mark {
    top: 28px;
    font-size: 12px;
    font-family: "font-Semibold";
    &-text {
      color: variables.$baseTextColor;
    }
  }
  .ant-slider-with-marks {
    margin-bottom: 32px;
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: variables.$linkColor;
  }
  // .slider-text {
  //   color: variables.$baseTextColor;
  //   font-size: 14px;
  //   font-family: Font-Medium;
  // }
}
