@use "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.tabs {
  .ant-tabs-top {
    > .ant-tabs-nav::before {
      border-bottom: 2px solid variables.$borderColor;
    }
  }
}

.result-pdf {
  margin-bottom: 20px;
  .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: -10px;
  }
  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: -10px;
  }
  .result-occurrences {
    text-align: center;
    color: variables.$baseTextColor;

    &-content {
      // border: 1px solid variables.$linkColor;
      padding: 5px;
      border-radius: 4px;
      border-radius: 4px;
      // box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
      display: flex !important;
      gap: 0.5rem;

      p {
        font-size: 12px;
      }

      &.active {
        color: variables.$linkColor;
        border: 1px solid variables.$linkColor;

        p {
          color: variables.$linkColor;
        }
      }
    }

    &-title {
      margin: 16px 0 10px 0;
      font-family: Font-Semibold;
      @include font_size_color(12px, variables.$titleColor, null, null);
      .edit-icon {
        margin-left: auto;
        // font-weight: 400;
        cursor: pointer;
        color: variables.$linkColor;
      }
      span {
        font-family: Font-Regular;
      }
    }
  }

  a {
    color: variables.$baseTextColor;
  }

  // .timeline-group {
  //   margin-top: 10px;
  //   // padding: 6px 15px 6px 30px;
  //   max-height: 300px;
  //   overflow-y: auto;
  //   flex-wrap: nowrap;

  .occurrences-group {
    max-height: 300px;
    overflow-y: auto;
    flex-wrap: nowrap;
    padding-right: 10px;
  }

  .result-occurrences-count {
    font-size: 12px;
    font-family: Font-Bold;
    // padding: 6px 8px;
    border-radius: 50%;
    background: variables.$linkColor;
    color: variables.$baseColor;
    width: 24px;
    height: 24px;
    display: flex;
  }
  // }

  //   .show-multiple-pdf-holder.flex {
  //     overflow: hidden;
  //     flex-wrap: nowrap;
  //   }

  //   .pdf_multiple-holder {
  //     transition: all 0.3s ease;
  //     flex-wrap: nowrap;
  //     flex-grow: 0;
  //     overflow: hidden;

  //     div,
  //     svg {
  //       width: 0;
  //     }
  //   }

  //   .pdf {
  //     padding-left: 40px;
  //   }

  //   .pdf-current {
  //     cursor: pointer;
  //   }

  //   .pdf-text {
  //     display: block;
  //   }
  // }

  // .pdf-check-holder {
  //   display: flex;
  // }

  // .pdfCheckbox {
  //   position: absolute;
  //   display: none;

  //   &:checked ~ .pdf_multiple-holder {
  //     transform: translateX(0);
  //     flex-grow: 0;
  //     padding-left: 40px;
  //     gap: 40px;
  //     overflow: visible;

  //     div,
  //     svg {
  //       width: auto;
  //     }
  //   }
}
/*--------Drawer-----------*/

// .site-drawer-render-in-current-wrapper {
//   position: relative;
//   // height: 200px;
//   overflow: hidden;
//   border-radius: 2px;

//   .ant-drawer-content {
//     background: #36383e;
//     text-align: center;

//     .ant-drawer-header {
//       border-bottom: none;
//       padding-bottom: 0px;
//     }

//     .ant-drawer-header-title {
//       justify-content: end;

//       svg {
//         color: variables.$baseTextColor;
//         font-size: 20px;
//       }

//       .ant-drawer-close {
//         margin-right: 0px;
//       }
//     }
//   }

//   .drawer-btn-group {
//     margin-bottom: 30px;

//     .ant-btn {
//       // &.ant-btn-default {
//       background: transparent;
//       color: variables.$baseTextColor;
//       border: 1px solid variables.$borderColor;
//       border-radius: 6px;

//       // }
//     }

//     .active {
//       background-color: #314e98;
//       border-color: #314e98;
//     }
//   }

//   .ant-drawer-body {
//     overflow-y: auto;
//     max-height: 175px;
//   }
// }

.ant-carousel {
  font-size: 12px;
  margin-top: 20px;
  .slick-initialized .slick-slide {
    // margin:0 10px;
    > div {
      padding-right: 20px;
      padding-block: 17px;
      //   border: 1px solid variables.$linkColor;
      //   border-radius: 4px;
      //   box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    }
  }
}

.ant-carousel .carousel-arrow {
  padding: 0 15px;
}
.react-pdf__Page__canvas {
  border-radius: 6px;
  border: 1px solid variables.$borderColor;
}

.tab-active {
  p {
    color: variables.$linkColor;
  }
}
