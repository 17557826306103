@use  "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";
.gender-stats {
    display: flex;
    align-items: center;
    gap: 10px;
    padding:0 0 18px 20px ;  
    color: variables.$baseTextColor;
    &:nth-child(odd) {
        padding-left: 0;
    }  
    &-info {
      @include font_size_color(14px,  inherit, normal);     
    }  
    .ant-progress-circle .ant-progress-text {
      @include font_size_color(14px,  variables.$baseTextColor, 500);          
    }  
    &-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);  
      &:before {
        content: none;
      }
    }  
    &-status {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      color: variables.$baseTextColor;
      gap: 5px;
      margin: 0 0 35px 0;  
      .circle {
        --size: 15px;
        display: inline-block;
        width: var(--size);
        height: var(--size);
        border-radius: calc(var(--size) / 2);
        border: 3px solid var(--circleborder);
  
        &.red {
          --circleborder: #dd2025;
        }
  
        &.yellow {
          --circleborder: #f5c65d;
        }
  
        &.green {
          --circleborder: #44c658;
        }
      }
    }  
    &-iconlist {
      display: flex;
      align-items: center;
      gap: 10px;
      @include font_size_color(14px, null, 400);    
    }
    }
    .basic-detail-content{
      .ant-card .ant-card-body{
        min-height: 320px;
      }
    }
    .ant-col-24{
      .basic-detail-title{
        margin-top: 35px;
      }
    }