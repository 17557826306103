.ant-select-dropdown {
  color: $baseTextColor;
  font-size: 1rem;
  box-shadow: none;
  background: $baseColor;
  border: 1px solid $linkColor;
  border-radius: 4px;

  .ant-select-item {
    min-height: auto;
    font-size: 12px;
    color: $baseTextColor;
  }

  .ant-select-item-option {
    padding: 0.375rem;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $linkColor;
    font-weight: normal;
    background-color: transparent;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: $baseColor;
    background: $linkColor;
    // .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    //   .ant-select-item-option-state {
    //   color: $baseColor;
    // }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: $linkColor;
  }

  // .ant-select-item-option-selected {
  //   .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  //     .ant-select-item-option-state {
  //     color: $baseColor;
  //   }
  // }
}
// .align-dropdown {
//   left: 267px !important;
//   top: 518px !important;
// }
