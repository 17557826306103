@import "../../assets/sass/variables";
// @import "../../assets/sass/mixins";

.user-page {
  height: 100vh;

  a {
    font-family: Font-Medium;
  }

  &-container {
    width: 35%;
    gap: 10px;
  }
  .login {
    &-title {
      font-size: 30px;
      color: $tabdetail;
      font-family: Font-Semibold;
      margin: 20px 0 30px 0;
    }
  }
  .autonomizeModal {
    .ant-form-item {
      margin-bottom: 40px;
    }
  }
}
.login-content-modal {
  padding: 30px 90px;
}
.email-confirmation {
  svg {
    width: 53px;
    height: 45px;
  }
  h3 {
    margin-top: 20px;
    color: $tabdetail;
    font-family: Font-Semibold;
  }
}

.user-form {
  h2 {
    font-family: Font-Semibold;
    margin: 20px 0 40px;
  }
  &-message {
    padding-bottom: 30px;
  }
  .ant-input-affix-wrapper {
    .ant-input {
      margin-top: 0px;
      box-shadow: 0px 0px 0px;
    }
    // &:hover {
    //   border-color: $linkColor;
    // }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $linkColor;
    box-shadow: 0 0 0 2px rgb(59 112 205 / 20%);
  }
}
.brand-logo-img {
  height: 48px;
}

//   // .brand-logo {
//   //   flex-basis: 55%;
//   //   background: url(../../assets/images/background.png) no-repeat fixed;
//   //   align-items: center;
//   //   justify-content: center;
//   // }

//   .user-form {
//     flex-basis: 45%;
//     height: 100%;
//     background: #222328;

//     h2 {
//       font-size: 32px;
//       margin-bottom: 0px;
//       line-height: 1;
//       color: $baseColor;
//     }

//     &-message {
//       font-size: 16px;
//       color: $textPrimaryColor;
//       margin-bottom: 0px;
//     }

//     &-content {
//       height: 100vh;
//       gap: 10px;
//       flex-direction: column;
//       justify-content: center;
//       padding: 0 15%;

//       .ant-form-item {
//         margin-top: 30px;
//       }

//       a {
//         color: $primaryColor;
//         font-family: 16px;
//       }

//       .aiButton {
//         width: 124px;
//         min-width: 124px;
//       }
//     }

//     /*--------Email confirmation--------*/

//     .email-confirmation {
//       h2 {
//         margin: 10px 0;
//       }
//     }

//     /*--------Reset Password--------*/

//     .reset-password {
//       .ant-form-item-extra {
//         margin-top: 10px;
//         font-size: 14px;
//         color: $textSecondaryColor;
//       }
//     }

//   .ant-form-item-label > label {
//     color: $textSecondaryColor;
//   }

//   .ant-input {
//     background: #222328;
//     color: $textPrimaryColor !important;
//     border: 0px;
//     border-bottom: 1px solid $borderColor;
//     padding-bottom: 20px;
//     font-size: 18px;
//   }

//   .ant-input-affix-wrapper {
//     padding: 0px;
//     background-color: transparent;
//     border: 0px;
//     border-bottom: 1px solid $borderColor;

//     input.ant-input {
//       padding: 4px 11px 20px 11px;
//     }
//   }

//   .ant-input:focus,
//   .ant-input-affix-wrapper:focus,
//   .ant-input-affix-wrapper-focused {
//     box-shadow: 0 0 0 2px rgb(24 144 255 / 0%);
//   }

//   .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
//   .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
//     box-shadow: 0 0 0 2px rgb(255 77 79 / 0%);
//   }

//   .ant-input::placeholder {
//     color: $textPrimaryColor;
//     font-size: 18px;
//     padding-left: -11px;
//   }

//   .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
//   .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
//   .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
//   .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
//     background: #222328;
//     // border:0px;
//   }

//   .ant-input-password-icon.anticon,
//   .ant-input-password-icon.anticon:hover {
//     color: rgb(255 255 255 / 45%);
//   }

//   // .ant-form-item-explain-error {
//   //   margin-top: 8px;
//   // }
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
