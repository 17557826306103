@import "../../../assets/sass/variables";

.multiple-role-radio {
  + .tr {
    margin-top: 30px;

    .m-l-20 {
      margin-left: 20px;
    }
  }
  &.ant-card {
    box-shadow: none;
    border: 0px;
  }

  .ant-radio-group {
    width: 100%;

    .gp-none {
      gap: 0px !important;
    }

    .ant-space-item {
      border-bottom: 1px solid $borderColor;

      &:first-child {
        .ant-radio-wrapper {
          padding-top: 0px;
        }
      }
      .ant-radio-wrapper {
        padding: 10px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .ant-radio {
        order: 2;

        &-inner {
          width: 20px;
          height: 20px;
          border: 0px;
        }
      }
      .ant-radio-checked .ant-radio-inner {
        border: 0px;
      }
      .ant-radio-input:focus + .ant-radio-inner,
      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner {
        box-shadow: 0px 0px 0px;
        border: 0px;
      }
      .ant-radio-checked .ant-radio-inner:after {
        width: 40px;
        height: 40px;
        background: transparent url(../../../assets/images/Done-check.png);
        background-size: contain;
        left: -2px;
        top: -2px;
      }
    }
  }
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0px;
}
