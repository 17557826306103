.reset-user-password {
  &.ant-modal {
    min-width: initial;
  }
  &.autonomizeModal .aiButton {
    min-width: 130px;
    min-width: 140px;
    width: 140px;
  }
  .ant-card {
    border: none;
    margin-bottom: 20px;
    box-shadow: none;
  }
  .text-bold {
    font-family: Font-Semibold;
    margin: 0px;
  }
  .m-t-24 {
    margin-top: 24px;
  }
}
