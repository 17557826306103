@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";
.profile-info {
  &-first-row,
  &-second-row,
  &-third-row {
    padding: 15px 0;
    border-bottom: 1px solid variables.$borderColor;
  }
  &-first-row {
    h5,
    button {
      display: block;
      margin-top: 16px;
    }
  }

  &-third-row > div,
  &-second-row > div {
    padding-bottom: 15px;
    display: flex;
    gap: 10px;
    &:last-child {
      padding-bottom: 0px;
    }
  }

  &-second-row {
    h5 {
      min-width: fit-content;
    }
  }

  &-forth-row {
    padding-top: 15px;
    h2 {
      font-size: 12px;
      padding-bottom: 15px;
    }
  }

  &-name {
    @include font_size_color(16px, variables.$titleColor, null, null, Font-Semibold);
  }

  &-desc {
    @include font_size_color(12px, variables.$baseTextColor);
  }
}
