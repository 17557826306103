.annotation-header {
  margin-top: 10px;
  margin-bottom: 20px;
}
.annotate-text {
  white-space: pre-line;
  > div {
    font-size: 14px;
    line-height: 2.6;
  }
  mark {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    border-radius: 4px;
    padding: 5px !important;

    span {
      font-size: 12px !important;
      padding: 3px;
      margin: 0 5px !important;
      border-radius: 4px;
      background-color: #fff;
      font-family: Font-Bold;
      color: #111;
    }
  }
}

@media screen and (max-width: 1920px) {
  .e {
    &-content {
      .annotate-text {
        min-height: 1176px !important;
        max-height: 1176px !important;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .e {
    &-content {
      .annotate-text {
        min-height: 866px !important;
        max-height: 866px !important;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .e {
    &-content {
      .annotate-text {
        min-height: 764px !important;
        max-height: 764px !important;
      }
    }
  }
}
