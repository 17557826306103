@use  "../../../assets/sass/variables";
@import "../../../assets/sass/_accordion.scss";
@import "../../../assets/sass/mixins";
.timeline-group{
  padding-right: 10px;
}
.timeline{
  @include display-flex(flex, null, center, 10px, nowrap);
    :first-child{    
      width:calc(100% - 90px);
      text-align: left;
      span{
        @include text_ellipsis;
        width: 100%;
      }
    }
    :nth-child(2){
      width: 25px;
      color: variables.$linkColor;
    }
    :last-child{
      width: 28px;
    }    
}