@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";
.edu-marker{
  width: 12px;
  height: 12px;
  display: block;
  border: 3px solid;
  border-radius: 50%;
}
.project-status {
  margin-bottom: 1.25rem;
  .ant-card .ant-card-body{
    min-height: 320px;
  }
  &-info {
    @include font_size_color(null, variables.$baseTextColor, 700);   
    margin-bottom: 0;
    &-wrap {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      text-align: center;
    }
  }
  &-chip {  
    background: transparent;
    border-radius: 20px;
    padding: 2px 6px;
   
    
    &.blue-s {
      background: rgba(178, 228, 250, 0.31);
      color: #2ab9f7;
    }

    &.primary {
      color: #feac3b;
      background: rgba(255, 209, 145, 0.22);
    }

    &.red {
      background: rgba(255, 122, 76, 0.13);
      color: #fc794c;
    }
  }

  .ant-progress {
    padding: 1.5rem 0;
  }
  .ai-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-left: 0px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    text-align: left;
  }

  .ai-select {
    width: 100%;
      &-box {
    margin-top: 10px;
    }
    &.ai-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      // max-width: 210px;
    }
  }

  /*--------Progress bar---------*/
  .ant-progress-circle .ant-progress-text {
    color: variables.$baseTextColor;
  }
  .ant-progress-circle-trail {
    stroke: rgba(94, 111, 120, 0.48);
    stroke-width: 16px;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #fc794c;
    stroke-width: 16px;
  }
  .ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: red;
  }

  &-progress {
    width: 230px;
    height: 230px;
    margin: auto;
.CircularProgressbar {
    width: 100%;
    &.CircularProgressbar-trail {
      stroke: transparent;
    }
    &-text {
      fill: variables.$baseTextColor;
      font-size: 20px;
      dominant-baseline: middle;
      text-anchor: middle;
    }
  }
    .not-fit .CircularProgressbar-path {
      stroke: #fc794c;
    }
    .good-fit .CircularProgressbar-path {
      stroke: #feac3b;
    }
    .not-reviewed .CircularProgressbar-path {
      stroke: #2ab9f7;
    }
    .recruited .CircularProgressbar-path {
      stroke: #44c658;
    }
    .background .CircularProgressbar-trail {
      stroke: rgba(94, 111, 120, 0.48);
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: 0px 0px 0px 0px !important;
  }
}

.people-img {
  top: 42%;
  transform: translateY(-50%);
}
