@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.result {
  .ant-skeleton {
    grid-area: result;
  }

  .ant-card-bordered {
    border: none;
    border-radius: 6px;
    color: $baseTextColor;
    margin-bottom: 1.25rem;
    font-size: 1rem;

    .ant-card-body {
      padding: 1.25rem 1.6875rem;
    }
  }

  .result-title {
    grid-area: heading;
    padding-bottom: 0.625rem;
    margin: 25px 0 15px 0;

    h2 {
      @include font_size_color(14px, $baseTextColor, 500, 19px);
      span {
        color: $baseTextColor;
      }
    }

    &.flex {
      flex-wrap: nowrap;
    }

    .saveSearch {
      width: 100%;
      max-width: 135px;
      cursor: pointer;

      h2 {
        margin-bottom: 0px;
        color: $linkColor;
        font-size: 12px;
      }
      svg {
        // width: 10px;
        // height: 10px;
        fill: $linkColor;
        color: $linkColor;
      }

      &.gp {
        gap: 5px;
      }
    }
  }

  .recent-searches {
    border: var(--borderwidth, 1px) solid #e7ecf4;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 5%);

    .ant-table-container table {
      .ant-table-cell {
        &:nth-child(4) {
          width: 150px;
          text-align: center;
        }
        &:last-child {
          text-align: center;
          width: 150px;
        }
      }
    }
  }

  // .my-saved-searches {
  //   .saved-searches {
  //     background: $cardBgColor;
  //     padding: 1rem 1.25rem;
  //     border-radius: 6px;

  //     .searches {
  //       &-h2 {
  //         font-size: 1rem;
  //       }

  //       &-li {
  //         border-bottom: 0px;
  //         padding: 0.6875rem 0;
  //         grid-template-columns: 1fr 15px;
  //         column-gap: 10px;

  //         .hide2 {
  //           display: none;
  //         }
  //       }

  //       &-title {
  //         line-height: 1.25rem;
  //         font-size: 1rem;
  //       }

  //       &-date {
  //         padding-top: 0.7rem;
  //         grid-row: 2 / 3;
  //       }
  //     }

  //     ul {
  //       margin-bottom: 0px;
  //       padding-right: 10px;
  //       margin-right: -10px;

  //       .saved-icon {
  //         margin-top: 3px;
  //       }
  //     }
  //   }
  // }

  .card-content {
    display: grid;
    margin-bottom: 0px;
    grid-template-columns: auto 1fr 1fr 1fr;
    column-gap: 30px;

    &-score {
      grid-row: 1 / span 2;
      justify-items: center;
      display: grid;
      margin-right: 10px;

      .ant-progress-inner {
        margin-top: 1rem;
        width: 117px;
        height: 117px;
      }

      .ant-progress-circle .ant-progress-text {
        color: $baseColor;
        font-size: 18px;
      }

      .ant-progress-circle-trail {
        stroke: #6b6565;
        stroke-width: 11px;
      }

      // .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      //   stroke: $primaryColor;
      //   stroke-width: 11px;
      // }
    }

    span {
      display: block;
      font-size: 14px;
      color: $textSecondaryColor;
    }

    &-information {
      .flex {
        gap: 10px;

        span {
          color: $baseColor;
          margin-top: 5px;
        }
      }
    }

    &-text {
      grid-column: 2 / span 2;
    }

    &-link {
      color: $baseColor;
      justify-self: end;
      align-self: end;

      a {
        color: $baseColor;
      }
    }
  }

  .loading {
    height: 20px;
    background: rgba(190, 190, 190, 0.2);
    border-radius: 2px;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: inline-flex;

    &:after {
      position: absolute;
      inset: 0 -150%;
      background: linear-gradient(
        90deg,
        rgba(190, 190, 190, 0.2) 25%,
        rgba(129, 129, 129, 0.24) 37%,
        rgba(190, 190, 190, 0.2) 63%
      );
      -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
      animation: ant-skeleton-loading 1.4s ease infinite;
      content: "";
    }
  }
}

@-webkit-keyframes ant-skeleton-loading {
  0% {
    transform: translate(-37.5%);
  }

  to {
    transform: translate(37.5%);
  }
}

.search-result {
  table {
    width: 100%;
    border-radius: 6px;

    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $baseTextColor;
    }

    tr {
      border-bottom: 1px solid $borderColor;
    }

    tbody {
      tr:last-child {
        border-bottom: 0px;
      }
      & > tr:hover > td,
      & > tr > td:hover {
        background: rgba(232, 173, 84, 10%);
      }
    }

    td,
    th {
      padding: 0.75rem 1.25rem;
      text-align: left;
      @include font_size_color(12px, $baseTextColor, 600, 15px);
    }

    td:first-child,
    th:first-child {
      text-align: left;
    }

    td:nth-child(3),
    th:nth-child(3) {
      word-break: break-word;
      min-width: 200px;
      max-width: 300px;
    }

    td {
      @include font_size_color(12px, $baseTextColor, 400, 15px);
    }

    .ant-progress-text {
      color: $baseTextColor;
    }
  }
}
