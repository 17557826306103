// $bgColor: #1c1d22;
// $primaryColor: #e8ae55;
// $buttonHoverColor: #da8e19;
// $secondaryColor: #314e98;
// $textPrimaryColor: #ffffff;
$textSecondaryColor: #9e9e9e;
// $text-color1: #a4a5a7;
// $placeTextColor: #505053;
// $cardBgColor: #303136;
// $orange: #d87e14;
// $red: #c2334d;
// $blue: #4743db; 

// $lightgray: #ededed;

$tabdetail: #1e293b;
$green: #44c658;
$bgBaseColor: #222328;
$baseColor: #ffffff;
$baseTextColor: #475569;
$titleColor: #301934;
$smTitleColor: #02462d;
$linkColor: #3b70cd;
$borderColor: #e7ecf4;
$CardBorderColor: #F5F9FF;
$tabBtnColor: #fcfcfc;
$tabBtnFillColor: #ebf1fa;
$selectRowColor: #f9fbff;
