.title{
  margin-top: 30px;
}
.clientDetails-description{
  .sub-title{
    font-size: 12px;
    font-family: Font-Semibold;
    padding-bottom: 5px;
  }
  p{
    font-size: 12px;
    font-size: Font-Regular;
  }
}