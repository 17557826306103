@use "../../assets/sass/variables";

.top-search-result {
  .ant-card {
    border-radius: 4px;
    border: 1px solid variables.$borderColor;
    padding: 10px 12px;
    height: 100%;
  }

  .ant-card:not(.default-padding) .ant-card-body {
    padding: 10px 12px;
  }

  h2 {
    // border-bottom: 1px solid variables.$borderColor;
    // padding-bottom: 10px;
    padding-top: 45px;
  }

  /*overrite ant css*/

  .ant-progress {
    grid-row: 1/3;
    align-self: center;
  }

  .ant-progress-circle .ant-progress-text {
    color: variables.$baseTextColor;
  }

  .projectsList {
    padding: 0 11px;

    // h2 {
    //   border: 0px;
    //   padding-bottom: 0px;
    //   align-self: flex-end;
    //   margin-bottom: 0px;
    // }

    // .aiButton {
    //   align-self: flex-end;
    // }

    // .dataAdmin-title {
    //   padding-bottom: 10px;
    //   margin-bottom: 20px;
    // }

    // .client-table {
    // &.dataproject {
    //   margin-top: 20px;
    //   border: 1px solid variables.$borderColor;
    //   box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    //   border-radius: 4px;

    //   .ant-table-container,
    //   .ant-table-container table > thead > tr > th {
    //     //   text-align: center;
    //     padding: 0;
    //     border-bottom: 0px;
    //   }

    // .ant-table-container,
    // .ant-table-container table > tbody > tr > td {
    //   text-align: center;
    // }

    // .ant-table-container,
    // .ant-table-container table > tbody > tr > td:first-child {
    //   text-align: left;
    // }

    // .ant-table-container,
    // .ant-table-container table > thead > tr:first-child th:first-child {
    //   text-align: left;
    // }
    // }
    // }
  }
}
