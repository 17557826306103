@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";


.search-modal {
  .ant-modal-body{
  max-height: calc(100vh - 150px);
  overflow: auto;
  }
  .search-title{
    @include font_size_color(12px, $baseTextColor, 500, 15px, Font-Medium);
  }
  .ant-modal-content {  
    border-radius: 6px;
  }

  .ant-modal-footer {
    border-top: none;
    padding-bottom: 20px;
  }

  .modal-date {
    background: transparent;
    padding: 10px 15px;
    color: $baseTextColor;
    border-radius: 6px;
    border: 1px solid $borderColor;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    .ant-picker-suffix {
      color: $baseTextColor;
    }

    .ant-picker-input > input {
      color: $baseTextColor;
    }
  }

  &-row {
    @include display-flex(flex, null, center, 40px, wrap);
  }

  .hr {
    border-top: 1px solid $borderColor;
    padding-top: 15px;
    margin-top: 20px;
  }

  &-btn-group {
    @include display-flex(flex, null, center, 10px, wrap);

    .modal-btn-fill:not(.fill-orange) {
      background-color: transparent;
      border-color: $linkColor;
      color: $linkColor;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: $linkColor;
        color: $linkColor;
      }
    }

    .modal-btn-link {
      color: $baseTextColor;

      &:hover,
      &:active {
        color:$baseTextColor;
      }
    }
  }

  button {
    @include font_size_color(12px, $baseTextColor, null, 1, Font-Regular);
    border-radius: 4px;
    height: 30px;
    background: transparent;   
    border: 1px solid $borderColor;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    &:hover,
    &:focus {
      border-color: $linkColor;
      color: $linkColor;
    }

    &.fill-orange {
      background-color: $linkColor;
      border-color: $linkColor;
      height: 34px;
      padding: 4px 20px;

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: $linkColor;
        color: $linkColor;
      }
    }
  }

  &-footer {
    @include display-flex(flex, flex-end, center, 15px, wrap);   
    span {
      cursor: pointer;     
    }
    &-apply {
      @include font_size_color(12px, $linkColor );    
      border: 1px solid $linkColor;
      padding: 5px 20px;
      border-radius:4px ;
    }

    &-link {
      @include font_size_color(12px, $baseTextColor, 600,null, Font-Semibold );   
      text-decoration: none;      
    }
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    background: transparent;
    border-color: $borderColor;
    color: $baseTextColor;
    opacity: 0.5;
  }
}

.btn1-grp {
  button {
    border: 1px solid transparent;
  }
}

.search-modal .outline{
  background: transparent;
&:hover {
  background: transparent;
  color: $linkColor;
  border-color: $linkColor;
}
}
.search-modal button.fill-orange{
  color: #fff;
}
.ant-picker-input>input{
  font-size: 12px;
}