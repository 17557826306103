@import "../../../assets/sass/variables";

.uploading-status {
  padding: 50px;

  p {
    color: $baseTextColor;
    font-family: Font-Semibold;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .ant-progress-bg {
    background: #45aa73;
  }

  .ant-progress-status-success {
    .ant-progress-bg {
      background: #45aa73;
    }
    .ant-progress-text {
      color: #45aa73;
    }
  }

  &-description {
    margin-top: 40px;
    // background: #38393e;
    // border: 1px solid #434448;
    border: 1px solid $borderColor;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 40px 40px 10px 40px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background: #45aa73;
        border: 4px solid $baseColor;
        outline: 1px solid #45aa73;

        > .ant-steps-icon {
          color: $baseColor;
          top: -5px;
          font-size: 12px;
        }
      }
    }
    .ant-steps-item-tail:after {
      height: 0px;
    }

    .ant-steps-item-tail:after {
      background-color: $borderColor;
    }

    .ant-steps-vertical {
      line-height: 1;
    }
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      left: 14px;
      padding: 32px 0 1px;
    }
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
      width: 3px;
    }

    .ant-steps-item-process {
      > .ant-steps-item-container {
        > .ant-steps-item-tail:after {
          background-color: $borderColor;
        }
      }
    }

    .ant-steps-item-finish,
    .ant-steps-item-process {
      > .ant-steps-item-container {
        > .ant-steps-item-tail:after {
          background-color: #45aa73;
        }
      }
    }

    .ant-steps-item-error {
      > .ant-steps-item-container {
        > .ant-steps-item-tail:after {
          display: none;
        }
      }
    }

    .ant-steps-item-finish,
    .ant-steps-item-error {
      > .ant-steps-item-container {
        > .ant-steps-item-content {
          padding-bottom: 30px;
          > .ant-steps-item-title {
            // color: $textSecondaryColor;
            font-size: 14px;
            font-family: Font-Medium;
          }
          > .ant-steps-item-description {
            // color: $textPrimaryColor;
            font-size: 12px;
            font-family: Font-Regular;
          }
        }
      }
    }

    .ant-steps-item-subtitle {
      // color: $textPrimaryColor;
      font-size: 14px;
      display: block;
      margin-left: 0px;
    }

    .ant-steps-item-error {
      .ant-steps-item-icon {
        background: #dd2025;
        border: 4px solid $baseColor;
        outline: 1px solid #dd2025;

        > .ant-steps-icon {
          color: $baseColor;
          top: -6px;
          font-size: 12px;
        }
      }
    }

    .ant-steps-item-process {
      > .ant-steps-item-container {
        > .ant-steps-item-content {
          padding-bottom: 30px;
          > .ant-steps-item-title {
            color: #a093d3;
          }
          > .ant-steps-item-description {
            color: #9d9d9d;
          }
        }
        > .ant-steps-item-icon {
          background: #a093d3;
          border: 4px solid #38393e;
          outline: 1px solid #a093d3;

          .ant-steps-icon {
            color: $baseColor;
            top: -5px;
            font-size: 12px;
          }
        }
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background: #606064;
        border: 4px solid #38393e;
        outline: 1px solid #606064;

        > .ant-steps-icon {
          color: #606064;
          top: -5px;
          font-size: 12px;
        }
      }
    }
  }

  .re-try {
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;

    p {
      font-size: 12px;
      font-family: Font-Regular;
      color: #ff4d4f;
    }
  }
}
