@use "../../../assets/sass/variables";
.insight {
  margin-top: 30px;
  .arrow {
    gap: 19px;
  }
  .ant-card {
    border-width: 1px;

    &-body {
      padding: 1.063rem 1.25rem !important;
    }
  }

  &-title-wrap {
    margin-bottom: 1rem;
  }

  &-h4 {
    // color: variables.$primaryColor;
    margin-bottom: 1rem;
  }

  &-content {
    display: grid;
    grid-template-columns: 3fr 5fr 4fr;
    gap: 1rem;

    .ant-card {
      background-color: #434448;
    }
  }
}

.location {
  grid-row: 2/3;

  &-content {
    gap: 0.5rem;
    border-bottom: 1px solid variables.$borderColor;
    padding-bottom: 0.75rem;
    align-items: flex-start;
    margin-bottom: 1rem;

    &.no-border {
      border-width: 0;
      margin-bottom: 0;
    }
  }

  &-img {
    margin-top: 10px;
  }
}

.diversity {
  grid-row: span 2;

  .gender-stats-status {
    margin-top: 0;
  }

  .gender-stats {
    gap: 10px;
    padding-left: 10px;

    .align {
      display: flex;
      gap: 6px;
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .gender-stats-wrapper {
      flex: auto;
    }
  }
}

.study-status {
  grid-row: span 2;

  &-row {
    gap: 0.8125rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid variables.$borderColor;
    margin-bottom: 10px;

    &:last-child {
      border: none;
      margin: 0;
    }

    p {
      font-size: 16px;
      color: variables.$baseColor;

      span {
        font-size: 21px;
      }
    }
  }
}
