@use "./variables";
@import "./mixins";
// Custmize accordian
.customize-accordion{ 
.ant-collapse {
    background: transparent;
    border: none;
    width: 100%;
    .ant-collapse-item > .ant-collapse-header {
      padding: 0 0 10px 0;
      .ant-collapse-header-text {
        @include font_size_color(12px, variables.$baseTextColor,null,null, Font-Semibold);
        background: transparent;
      }
    }
    .ant-collapse-item {
      border: none;
      margin-bottom: 30px;
    }
  }
  .ant-collapse-content {
    border: none;
    .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  transform: translateY(-50%) rotate(90deg) !important;
  opacity: 0.5;
  width: 11px;
  height: 11px;
}