@use "../../assets/sass/variables";

.mail-service-config {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  // margin-bottom: 60px;

  .font-medium {
    font-family: Font-Medium;
    color: variables.$baseTextColor;
  }
  .ant-form-item.save-btn {
    grid-row: 3;
    grid-column: 1/3;

    &.no-grid-row {
      grid-row: inherit;
    }
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
  }
  .ant-card {
    padding: 30px;
    margin-top: 20px;
  }

  &.autonomizeModal {
    .ant-form label {
      font-size: 12px;
    }

    .ant-input,
    .ant-input-affix-wrapper {
      font-size: 12px;
    }
    .ant-form-item:last-child {
      padding-top: 20px;
    }

    .ant-form-item-label > label {
      font-family: Font-Medium;
    }
  }

  .equal-height {
    height: calc(100% - 42px);

    p {
      font-size: 12px;
      font-family: Font-Medium;
      min-width: 110px;
    }
  }

  &.grid-no {
    display: inherit;
  }
  // .ant-select:not(.ant-select-customize-input) .ant-select-arrow {
  //   left: auto;
  //   color: variables.$textPrimaryColor;
  // }
  // .ai-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  //   padding-left: 0px;
  // }
  // .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  //   color: variables.$textPrimaryColor;
  // }
  // .ant-select:hover .ant-select-clear {
  //   opacity: 0;
  // }

  // .ant-form label {
  //   font-size: 16px;
  //   color: variables.$textPrimaryColor;
  // }

  // .ant-form-item-label > label {
  //   font-size: 16px;
  //   color: variables.$textPrimaryColor;
  // }
  // .ant-input {
  //   border-radius: 6px;
  //   background: variables.$cardBgColor;
  //   color: variables.$textPrimaryColor;
  //   border: 1px solid variables.$borderColor;
  //   font-size: 16px;
  //   padding: 8px;
  // }

  // .ant-input:hover,
  // .ant-input:focus {
  //   border-color: variables.$borderColor;
  // }

  // .form-grid {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   column-gap: 10%;
  // }

  // // ----------checkbox----------

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: variables.$linkColor;
    border-color: variables.$linkColor;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: variables.$linkColor;
  }

  .ant-checkbox-wrapper:first-child {
    margin-right: 40px;
  }

  // .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  // .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  //   background: transparent;
  // }
}
