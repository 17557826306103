@import "../../../assets/sass/mixins";
@import "../../../assets/sass/variables";

.info-main-heading {
  font-size: 26px;
  margin-bottom: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
  font-weight: 500;

  > *:not(:last-child) {
    margin-right: 50px;
  }

  &-wrapper {
    margin-bottom: 40px;
  }
}

.patient-card {
  height: calc(100% - 40px);

  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-card {
      flex: auto;
    }
    .immunization-history {
      .ant-table .ant-table-tbody {
        max-height: 355px;
      }
    }
  }

  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .listitem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
  }

  &.cyan {
    .ant-card-body {
      overflow-y: auto;
      max-height: 400px;
    }
    .pd-status-table {
      .ant-table-cell:last-child {
        text-align: left;
      }
    }
  }

  &.lifestyle {
    padding: 10px;
    font-size: 12px;
  }

  .pdf-icon {
    --size: 22px;
    width: var(--size);
    height: var(--size);
  }

  &.orange {
    .ant-card-body {
      padding: 0px;
      max-height: 400px;
      overflow-y: auto;
    }

    .reviewlist {
      display: flex;
      align-items: center;
      font-size: inherit;
      word-break: break-word;
      text-align: left;
      margin-bottom: 0px;

      svg {
        margin-right: 12px;
      }
    }
  }
}

.pdf-viewer {
  overflow: hidden;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.profile-info-grid {
  margin-bottom: 50px;
}

.lifetsyle {
  .ant-card {
    overflow-y: auto;
  }

  .aiButton {
    align-self: flex-end;
    min-width: 124px;
    max-width: 124px;
  }
}

.view-more-result {
  margin-top: auto;
  padding: 20px 10px;
  color: $titleColor;
  font-family: Font-Medium;
  font-size: 12px;

  button {
    font-family: Font-Regular;
  }
}
.search-select-row {
  margin-top: 30px;
}
