@use "../../assets/sass/variables";

.user-table {
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid variables.$CardBorderColor;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  .ant-table {
    .ant-table-tbody {
      max-height: calc(100vh - 250px);
    }
    .ant-table-container table .ant-table-cell:last-child {
      width: 210px;
      text-align: center;
    }
  }
}
