@use  "../../assets/sass/variables";
.title{
font-size: 16px;
color: variables.$tabdetail;
  margin-bottom: 30px;
  .add-btn{
    background: variables.$linkColor;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: variables.$baseColor;
  }
}