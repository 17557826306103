@use "../../assets/sass/variables";
@import "../../assets/sass/mixins";

.project-title {
  margin: 20px 0 30px 0;
  &-heading {
    @include font_size_color(12px, variables.$baseTextColor, null, 17px);
    font-family: Font-Semibold;
  }
  &-name {
    @include font_size_color(14px, variables.$linkColor, null, 17px);
    font-family: Font-Regular;
  }
}
