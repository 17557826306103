.ai-select {
  font-size: 12px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: $baseTextColor;
    border: 1px solid $borderColor;
    border-radius: 4px;
    height: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  .ant-select-arrow {
    color: #475569;
  }
}

// -----Form fields-------

// .ant-select:not(.ant-select-customize-input) .ant-select-arrow {
//   left: auto;
//   color: $baseColor;
// }
.ai-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-left: 0px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: $baseTextColor;
}
.ant-select:hover .ant-select-clear {
  opacity: 0;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $linkColor;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $linkColor;
  box-shadow: 0 0 0 2px rgb(59 112 205 / 20%);
}
