@use "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";

.card-content {
  &-title {
    @include font_size_color(14px, variables.$baseTextColor, 500, 19px);
    margin-bottom: 25px;
  }
  &.ant-card .ant-card-body {
    padding: 25px 30px;

    .input-wrap {
      // max-width: 881px;
      gap: 30px;
      margin: 0 auto;
      flex-wrap: nowrap;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:active,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:hover {
      background: transparent;
      color: variables.$linkColor;
      opacity: 0.5;
      border-color: variables.$linkColor;
    }
    .ant-input-group {
      &.ant-input-group-compact {
        position: relative;
        flex: auto;
        max-width: calc(100% - 242px);

        .ant-select {
          font-size: 12px;
          &-arrow {
            color: variables.$baseTextColor;
          }

          &-clear {
            color: variables.$textSecondaryColor;
            transform: translateY(-50%);
            margin: 0;
            opacity: 1;
            background: transparent;
            right: 12px;
          }

          &-selector {
            height: 42px;
            color: variables.$baseTextColor;
            border: 1px solid variables.$borderColor !important;
            box-shadow: none !important;
          }

          &-single {
            position: absolute;
            width: 20%;
            left: 0;
            z-index: 2;

            &.ant-select {
              .ant-select-selector {
                border-radius: 8px 0 0 8px;
                text-align: center;
              }
            }
          }

          &-multiple {
            width: 100%;
            z-index: 1;

            .ant-select {
              &-selector {
                border-radius: 4px;
                padding-right: 12px;
              }

              &-selection {
                &-item {
                  // background: transparent;
                  border: 1px solid variables.$linkColor;
                  border-radius: 4px;
                  padding: 6px 16px;
                  color: variables.$linkColor;
                  font-size: 14px;
                  line-height: 18px;
                  margin: 0;
                  height: auto;

                  &-remove {
                    position: absolute;
                    right: 5px;
                    color: variables.$baseTextColor;
                  }
                }
              }
            }
          }

          &-selection {
            &-overflow {
              padding-left: calc(20% + 10px);
              height: 100%;
              gap: 10px;
              flex-wrap: nowrap;
              overflow: auto hidden;
              scrollbar-width: none;

              &::-webkit-scrollbar {
                width: 0;
                height: 0;
              }
            }

            &-placeholder {
              font-size: 12px;
              padding-left: calc(20% + 5px);
              color: variables.$borderColor;
              line-height: 40px;
            }

            &-item {
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  .advance-search {
    a {
      @include font_size_color(12px, variables.$linkColor);
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  // .aiButton {
  //   min-width: 212px;
  //   max-width: 212px;
  // }

  /* dropdown */
  .ant-select-dropdown {
    &.search {
      &-drodpown {
        overflow: initial;

        .ant-select-item-option {
          // font-weight: 400;
          // font-size: 14px;
          line-height: 14px;
          color: variables.$baseTextColor;
          padding: 0.75rem;
          // background: variables.$cardBgColor;

          &-active {
            // background: variables.$bgColor;
          }
        }

        &.auto-complete {
          padding: 0.5rem 0 0.75rem;
          z-index: 0;
          border-radius: 0 0 8px 8px;
          border: 1px solid variables.$borderColor;
          border-radius: 8px;

          &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: -1px;
            width: calc(100% + 2px);
            height: 15px;
            // background-color: variables.$cardBgColor;
            border: 1px solid variables.$borderColor;
            border-bottom: none;
          }
        }

        &.select {
          padding-top: 7px;
          background-color: transparent;

          &:after {
            content: "";
            width: 10px;
            height: 10px;
            // background: variables.$cardBgColor;
            background: variables.$baseColor;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            top: 2.9px;
            border-radius: 1px;
            border: 1px solid variables.$borderColor;
            border-right-color: transparent;
            border-bottom-color: transparent;
          }

          > div {
            // background: variables.$cardBgColor;
            background: variables.$baseColor;
            border: 1px solid variables.$borderColor;
            border-radius: 8px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .selected-tag {
    margin-top: 25px;
    gap: 3px;
    &.customize-ant-tag .ant-tag{
      margin: 0;
    }
  }
}

.breif-desc {
  @include font_size_color(12px, variables.$baseTextColor, normal, 14px);
  margin-bottom: 15px;
}

.anticon .anticon-check {
  color: variables.$linkColor;
}

.card-content.ant-card
  .ant-card-body
  .ant-input-group.ant-input-group-compact
  .ant-select-multiple
  .ant-select-selection-item {
  border: none;
  color: variables.$borderColor;
  font-size: 12px;
  padding-left: 5px;
}

.search-dropdown {
  // .ant-select-selection-overflow-item {
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: calc(100% - calc(20% + 10px));
  // }
  // .ant-select-focused {
  //   .ant-select-selection-search {
  //     width: 100% !important;
  //     background: #fff;
  //   }
  //   .ant-select-selection-overflow-item {
  //     background-color: #fff;
  //   }
  // }
  .ant-select-multiple .ant-select-selection-item-content{
    color: variables.$baseTextColor;
  }

  &.ant-select-dropdown {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
      .ant-select-item-option-state {
      color: variables.$linkColor;
    }
  }
}
