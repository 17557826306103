.project-list {
  margin-top: 20px;
  .ant-table-container table .ant-table-cell:last-child {
    // width: 250px;
    text-align: center;
  }

  .ant-table .ant-table-tbody {
    max-height: calc(100vh - 310px);
  }
}

.no-padding{
  padding:0px;
}