@import "../../../assets/sass/variables";
@import "../../../assets/sass/mixins";
@import "../../../assets/sass/layout/tag.scss";
.project-specific-config {
  &-add {
    text-align: center;
    padding: 60px;
    // width: calc(50% - 20px);
    min-height: 320px;
    h3 {
      @include font-size-color(16px, $baseTextColor, null, 18px);
      padding-bottom: 10px;
    }
    p {
      padding-bottom: 20px;
      @include font-size-color(14px, $baseTextColor);
    }
  }
  &-edit {
    &-container {
      // background: #3f4045;
      padding: 20px;
      // border-radius: 6px;
      margin-top: 30px;
      .container {
        &-title {
          padding-bottom: 0px 0 20px 0;
          margin-bottom: 15px;
          border-bottom: 1px solid $CardBorderColor;
          .icon-btn {
            background: transparent;
            border: none;
            opacity: 0.5;

            &.ant-btn {
              background: transparent;
            }
            &.ant-btn:focus,
            &.ant-btn:hover {
              box-shadow: none;
              opacity: 1;
              outline: none;
            }
            &.ant-btn-icon-only {
              width: initial;
              height: initial;
              padding: 0px 0px;
              border: none;
            }
          }
        }
        &-content {
          h3 {
            color: $baseTextColor;
            font-size: 12px;
            font-family: Font-Medium;
            padding-bottom: 5px;
          }

          .columns-group {
            width: 65%;
          }

          .columns-group,
          .probability-group {
            padding-top: 25px;

            p {
              margin-top: 10px;
              font-size: 11px;
            }
          }
          .detail-group {
            // grid-column: 1/3;
            overflow-y: auto;
            max-height: 180px;
          }
        }
      }
    }
  }
  .ant-progress-circle .ant-progress-text {
    font-size: 12px;
    font-family: Font-Medium;
  }
}
// .project-configuration-container {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 20px;
// }
.ant-click-animating-node,
[ant-click-animating-without-extra-node="true"]:after {
  box-shadow: none;
}
