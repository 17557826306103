@use "../../../assets/sass/variables";
.project-specific {
  &-title {
    font-size: 22px;
    padding-bottom: 20px;

    &.tp-border {
      padding-top: 30px;
      border-top: 1px solid variables.$borderColor;
    }
  }

  .ant-card {
    padding: 0 auto;
  }

  .ant-table {
    padding: 0px;
  }

  .nsclc-table {
    .ant-table-cell:nth-child(4) {
      text-align: right;
    }
    .ant-table-cell:nth-child(3) {
      text-align: center;
    }
  }

  .specific-insight {
    text-decoration: none;
    font-size: 14px;
    color: variables.$baseTextColor;
    opacity: 0.4;
  }
  .ant-table .ant-table-tbody > tr > td,
  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table tfoot > tr > td,
  .ant-table .ant-table tfoot > tr > th {
    padding: 10px;
  }
}
