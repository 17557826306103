@use "../variables";
@import "../mixins";
.customize-ant-tag {
  .ant-tag {
    @include font-size-color(12px, variables.$baseTextColor, null, 15px, Font-Medium);
    background: variables.$baseColor;
    border-radius: 4px;
    border: 1px solid #f5f9ff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin: 0;
  }
  .ant-tag {
    &:last-child {
      background: transparent;
    }
  }
  .ant-tag-close-icon {
    color: variables.$baseTextColor;
    &:hover {
      color: variables.$linkColor;
    }
  }
  .ant-form-item-control-input-content {
    overflow-y: auto;
    max-height: 200px;
    .tag-input {
      width: 30% !important;
    }
  }
  // .ant-form-item-explain-error {
  //   margin-top: 4px;
  // }
  .ant-btn.ant-btn-block {
    width: 50px;
  }
}
