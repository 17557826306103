@import "../../../assets/sass/variables";

.project-card {
  &:last-child {
    padding-top: 20px;
  }
  &-label {
    font-family: Font-Semibold;
    display: block;
  }
  &-value {
    display: block;
    font-size: 28px;
    // font-weight: 700;
    // font-family: DmSans-Medium;
    // color: $textPrimaryColor;

    &-number {
      font-size: 16px;
    }
  }
}
