@use "../../assets/sass/variables";

.placeholder-text {
  font-size: 12px;
  text-align: left;
  margin: 10px;
  color: variables.$baseTextColor;
  opacity: 0.4;
  p {
    color: variables.$baseTextColor;
    // opacity: 0.4;
  }
}

.tabs {
  &.no-border {
    .ant-tabs-top {
      > .ant-tabs-nav {
        margin: 0px;
        &::before {
          border-bottom: 0px;
        }
      }
    }
  }
}

.biomarkers {
  .ant-table {
    .ant-table-tbody {
      max-height: 280px;
    }
  }
}

.gene-mutations {
  .ant-table {
    .ant-table-tbody {
      max-height: 357px;
    }
  }
}
