@use "../../../assets/sass/variables";
.project-detail-config {
  display: grid;
  gap: 20px;
  .ant-slider-disabled {
    opacity: 0.4;
  }
  .title {
    h3 {
      font-size: 12px;
      font-family: Font-Semibold;
    }
    margin-bottom: 5px;
    p {
      font-size: 12px;
    }
  }
  button {
    margin-left: auto;
  }
}
.outline {
  svg {
    width: 14px;
    height: 14px;
  }
  &.ant-btn > svg + span {
    margin-left: 8px;
  }
}

.customize-ant-tag {
  .ant-tag {
    margin: 10px 10px 0 0;
  }
  .ant-input.tag-input {
    width: 30% !important;
    height: 37px;
    margin: 10px 10px 10px 0;
    &:focus {
      outline: none;
      border-color: variables.$linkColor;
      box-shadow: 0 0 0 2px rgb(59 112 205 / 20%);
    }
  }
}
