@import "../../../assets/sass/variables";

// .dashboard {
//   display: grid;
//   grid-template-areas:
//     "result sidebar";
//   column-gap: 30px;
//   grid-template-columns: 1fr 297px;
//   margin-right: -1.875rem;
// }

.topSearch {

  padding: 0 10.5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;

  &.flex {
    flex-wrap: nowrap;
  }

  &.ant-card {
    padding: 20px;
  }


  // .dashboard-topSearch {
  //   h2 {
  //     color: $primaryColor;
  //     font-size: 16px;
  //     font-family: DmSans-Regular;
  //     padding-top: 0px;
  //   }
  // }

}

.ant-card-no-border {
  --borderwidth: 1px;
  --borderColor: #44454a;

}

// .top-search-result {
//   // padding-top: 1rem;

//   .ant-card {
//     height: 100%;
//   }

//   .ant-card:not(.default-padding) .ant-card-body {
//     padding: 10px 12px;
//   }

//   h2 {
//     border-bottom: 1px solid $borderColor;
//     padding-bottom: 10px;
//     padding-top: 45px;
//   }

//   /*overrite ant css*/

//   .ant-progress {
//     grid-row: 1/3;
//     align-self: center;
//   }

//   .ant-progress-circle .ant-progress-text {
//     color: $textPrimaryColor;
//   }

//   .projectsList {
//     padding: 0 11px;

//     h2 {
//       border: 0px;
//       padding-bottom: 0px;
//       align-self: flex-end;
//       margin-bottom: 0px;
//     }

//     .aiButton {
//       align-self: flex-end;
//     }

//     .dataAdmin-title {
//       padding-bottom: 10px;
//       margin-bottom: 20px;
//     }

//     .client-table {
//       &.dataproject {

//         .ant-table-container,
//         .ant-table-container table>thead>tr>th {
//           text-align: center;
//         }

//         .ant-table-container,
//         .ant-table-container table>tbody>tr>td {
//           text-align: center;
//         }

//         .ant-table-container,
//         .ant-table-container table>tbody>tr>td:first-child {
//           text-align: left;
//         }

//         .ant-table-container,
//         .ant-table-container table>thead>tr:first-child th:first-child {
//           text-align: left;
//         }
//       }
//     }
//   }
// }

.top-search {
  display: grid;
  grid-template-columns: 45px 1fr 1fr;
  gap: 6px 16px;

  h3 {
    grid-column: 2/4;
    margin-bottom: 0;
  }

  &-status {
    font-size: 12px;
    // color: $textSecondaryColor;
    grid-column: 2/3;
  }

  &-count {
    font-size: 14px;
    font-family: DmSans-Medium;
    grid-column: 3/4;
    justify-self: end;
  }

  &-total-count {
    // color: $textSecondaryColor;
  }
}

.top-search-sidebar {
  grid-area: sidebar;
  width: 257px;
}